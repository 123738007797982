import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import guestyBg from "../../../assests/tipperUiSection/guestyBg.png";
import partner from "../../../assests/tipperUiSection/partner.png";
import partnerStar from "../../../assests/tipperUiSection/partner_star.png";
import toptipperLogo from "../../../assests/tipperUiSection/toptipper-logo.png";
import ThemeButton from "../../../components/theme/themeButton";
import styles from "./guesty.module.scss";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  guestyIntegrationApi,
  validateEmailApi,
  validateIdentifierApi,
  validateNameApi,
} from "../../../store/slices/paymentSlices";
import { AppDispatch } from "../../../store/store";
import {
  guestyValidation,
  isValidEmail,
  isValidUrl,
  removeInitialSpace,
} from "../../../utils/functions";

interface FormValues {
  first_name: string;
  name: string;
  email: string;
  token: string;
  url_code: string;
  password: string;
  confirmPassword: string;
}

const GuestyIntegration = () => {
  const [orgName, setOrgName] = useState<any>();
  const [guestyEmail, setGuestyEmail] = useState<any>();
  const [guestyUrl, setGuestyUrl] = useState<any>();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik<FormValues>({
    initialValues: {
      first_name: "",
      name: "",
      email: "",
      token: "",
      url_code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: guestyValidation,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { confirmPassword, ...data } = values;
      try {
        // Dispatch the thunk action with the form values
        const result = await dispatch(guestyIntegrationApi(data));
        if (result?.payload?.status == 0) {
          toast.error(result?.payload?.message, { autoClose: false });
        } else if (result?.payload?.status == 1) {
          toast.success(result?.payload?.message, { autoClose: 5000 });
          resetForm();
          window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}`;
        }
      } catch (error: any) {
        toast.error(error.message, { autoClose: false });
      } finally {
        setSubmitting(false);
      }
    },
  });

  /**
   *
   * @function to call validateName api and set it's error
   */
  const handleOrgName = async (value: string) => {
    try {
      if (value) {
        const valid_name = await dispatch(
          validateNameApi({ name: value, type: 1 })
        );
        if (valid_name?.payload?.status == 0) {
          setOrgName(valid_name?.payload?.message);
        } else if (valid_name?.payload?.status == 1) {
          setOrgName("");
        }
      }
    } catch (error: any) {
      toast.error(error.message, { autoClose: false });
    }
  };

  /**
   *
   * @function to call validateEmail api and set it's error
   */
  const handleGuestyEmail = async (value: string) => {
    try {
      if (value) {
        const valid_email = await dispatch(validateEmailApi({ email: value }));
        if (valid_email?.payload?.status == 0) {
          setGuestyEmail(valid_email?.payload?.message);
        } else if (valid_email?.payload?.status == 1) {
          setGuestyEmail("");
        }
      }
    } catch (error: any) {
      toast.error(error.message, { autoClose: false });
    }
  };

  /**
   *
   * @function to call validateUrl api and set it's error
   */
  const handleGuestyUrl = async (value: string) => {
    try {
      if (value) {
        const valid_url = await dispatch(
          validateIdentifierApi({ type: 1, url_code: value })
        );
        if (valid_url?.payload?.status == 0) {
          setGuestyUrl(valid_url?.payload?.message);
        } else if (valid_url?.payload?.status == 1) {
          setGuestyUrl("");
        }
      }
    } catch (error: any) {
      toast.error(error.message, { autoClose: false });
    }
  };

  return (
    <div className={styles.guestyWrapper}>
      <div className={styles.guestyRight}>
        <div className={styles.formContent}>
          <div className={styles.header}>
            <img src={toptipperLogo} alt="" />
          </div>
          <div className={styles.leftContent}>
            <div className={styles.guesty_partner_content}>
              <div className={styles.guestyHeading}>
                <h3>Welcome!</h3>
                <p>
                  To begin the integration process and your tipping journey,
                  please fill out the information below. Once all the fields are
                  provided, our APIs will do the rest! You will recieve an email
                  with instructions on next steps.
                </p>
              </div>
              <div className={styles.guesty_partner_signup}>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Manager Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="first_name"
                      placeholder="Enter Manager Name"
                      onChange={(e) => {
                        if (e.target.value.startsWith(" ")) {
                          e.target.value = e.target.value.trimStart();
                        }
                        formik.handleChange(e);
                        formik.setFieldValue(
                          "first_name",
                          removeInitialSpace(e.target.value)
                        );
                      }}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className="text-danger">
                        {formik.errors.first_name}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Organization Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="name"
                      aria-describedby="emailHelp"
                      placeholder="Enter Organization Name"
                      onChange={(e) => {
                        // Prevent space at the beginning of the input
                        if (e.target.value.startsWith(" ")) {
                          e.target.value = e.target.value.trimStart();
                        }
                        // Call formik.handleChange
                        formik.handleChange(e);
                        // Set the processed value to Formik
                        formik.setFieldValue(
                          "name",
                          removeInitialSpace(e.target.value)
                        );
                      }}
                      onFocus={() => {
                        setOrgName("");
                      }}
                      value={formik.values.name}
                      onBlur={(e) => handleOrgName(e.target.value)}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-danger">{formik.errors.name}</div>
                    )}
                    {orgName && <div className="text-danger">{orgName}</div>}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="email"
                      placeholder="Enter Email Address"
                      onChange={(e) => {
                        if (e.target.value.startsWith(" ")) {
                          e.target.value = e.target.value.trimStart();
                        }
                        formik.handleChange(e);
                        formik.setFieldValue(
                          "email",
                          removeInitialSpace(e.target.value)
                        );
                      }}
                      value={formik.values.email}
                      onFocus={() => {
                        setGuestyEmail("");
                      }}
                      onBlur={(e) => {
                        if (isValidEmail(formik.values.email)) {
                          handleGuestyEmail(e.target.value);
                        }
                      }}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger">{formik.errors.email}</div>
                    )}
                    {guestyEmail && (
                      <div className="text-danger">{guestyEmail}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Integration Token</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="token"
                      placeholder="Enter Integration Token"
                      onChange={(e) => {
                        if (e.target.value.startsWith(" ")) {
                          e.target.value = e.target.value.trimStart();
                        }
                        formik.handleChange(e);
                        formik.setFieldValue(
                          "token",
                          removeInitialSpace(e.target.value)
                        );
                      }}
                      value={formik.values.token}
                    />
                    {formik.touched.token && formik.errors.token && (
                      <div className="text-danger">{formik.errors.token}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label">URL Format</label>
                    <div
                      className={styles.url_input}
                      style={{
                        display: "flex",
                        border: "0.5px solid #aeb1c2",
                        flexDirection: "row",
                      }}
                    >
                      <span className="input-group- d-block">
                        {`${process.env.REACT_APP_BASE_URL}guesty/`}
                      </span>
                      <input
                        type="text"
                        className={styles.custom_input}
                        name="url_code"
                        onChange={(e) => {
                          if (e.target.value.startsWith(" ")) {
                            e.target.value = e.target.value.trimStart();
                          }
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "url_code",
                            removeInitialSpace(e.target.value)
                          );
                        }}
                        value={formik.values.url_code}
                        onFocus={() => {
                          setGuestyUrl("");
                        }}
                        // onKeyDown={(e: any) => {
                        //   if (e.target.value == "" && e.key === " ") {
                        //     e.preventDefault();
                        //   }
                        // }}
                        onBlur={(e) => {
                          if (isValidUrl(formik.values.url_code)) {
                            handleGuestyUrl(e.target.value);
                          }
                        }}
                      />
                    </div>

                    {formik.touched.url_code && formik.errors.url_code && (
                      <div className="text-danger">
                        {formik.errors.url_code}
                      </div>
                    )}
                    {guestyUrl && (
                      <div className="text-danger">{guestyUrl}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Password</label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        name="password"
                        placeholder="Enter Password"
                        onChange={(e) => {
                          if (e.target.value.startsWith(" ")) {
                            e.target.value = e.target.value.trimStart();
                          }
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "password",
                            removeInitialSpace(e.target.value)
                          );
                        }}
                        value={formik.values.password}
                      // onKeyDown={(e: any) => {
                      //   if (e.target.value == "" && e.key === " ") {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                      <div className="input-group-append">
                        <span
                          className="eye_icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                    </div>

                    {formik.touched.password && formik.errors.password && (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          if (e.target.value.startsWith(" ")) {
                            e.target.value = e.target.value.trimStart();
                          }
                          formik.handleChange(e);
                          // Set the processed value to Formik
                          formik.setFieldValue(
                            "confirmPassword",
                            removeInitialSpace(e.target.value)
                          );
                        }}
                        value={formik.values.confirmPassword}
                      // onKeyDown={(e: any) => {
                      //   if (e.target.value == "" && e.key === " ") {
                      //     e.preventDefault();
                      //   }
                      // }}
                      />
                      <div className="input-group-append">
                        <span
                          className="eye_icon"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                    </div>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="text-danger">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>

                  <ThemeButton
                    className={styles.submit_btn}
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Submitting..." : "SUBMIT"}
                  </ThemeButton>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <div
            className={styles.mob_view}
            style={{ position: "relative", zIndex: "1" }}
          >
            <div className={styles.guesty_right_img}>
              <img src={partner} alt="" />
            </div>
            <div className={styles.partnerStar}>
              <img src={partnerStar} alt="" />
            </div>
            <div className={styles.guesty_partner_rightContent}>
              <div className={styles.content}>
                <h5>
                  Now it's easier than ever to <br />
                  integrate your
                </h5>
                <div className={styles.partner_content}>
                  <h2 className={styles.guesty}>Guesty</h2>
                  <h5>dashboard</h5>
                </div>
                <div className={styles.partner_content}>
                  <h5>with</h5>
                  <h2 className={styles.toptipper}>TopTipper</h2>
                </div>
                <div className={styles.guesty_points}>
                  <div className={styles.partner_content}>
                    <span className={styles.first}>01. </span>
                    <p>Fill out the form.</p>
                  </div>
                  <div className={styles.partner_content}>
                    <span className={styles.first}>02. </span>
                    <p>
                      Follow the prompts in the onboard <br /> email
                    </p>
                  </div>
                  <div className={styles.partner_content}>
                    <span className={styles.first}>03. </span>
                    <p>
                      You're staff is all set to recieve tips <br /> from
                      guests.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right_side}>
            <figure>
              <img src={guestyBg} alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestyIntegration;
