import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import oops from "../assests/tipperUiSection/paynow.png";
import { reviewLinksApi, sendTipApi } from "../store/slices/paymentSlices";
import { AppDispatch, RootState } from "../store/store";
import styles from "./stripePayment.module.scss";
import ThemeButton from "./theme/themeButton";
import ConfirmationModal from "./tipperUiSectionLayout/modal/ConfirmationModal";
import { toast } from "react-toastify";
export default function StripePayment() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [stripeLoading, setStripeLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { sendTipLoading, postTipAmount, isAllThreeFeatures, property_service_id } = useSelector(
    (store: RootState) => store.paymentSlices
  );
  const { sessionId } = useSelector(
    (store: RootState) => store.sessionSlice
  );
  const [errorModal, setErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleErrorModal = () => setErrorModal(!errorModal);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const paymentElement = elements.getElement(PaymentElement);
    if (!paymentElement) {
      setError(
        "Payment element is not yet available. Please refresh or try again later."
      );
      return;
    }

    if (!termsChecked) {
      // Check if terms are agreed upon
      setError("You must agree to the terms and conditions");
      setLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: process.env.REACT_APP_WEBSITE_URL + "payment-completed",
      },
    });

    if (result?.hasOwnProperty("error")) {
      toast.error((result as any)?.error?.message);
      if (result?.error?.type != 'validation_error') {
        dispatch(reviewLinksApi({
          data: {
            send_tip_screen: true,
            error_reason: result?.error?.message
          }, sessionId
        }))
      }

    }

    if (result) {
      if (result.error && !error && email.length > 0) {
        // Show error modal if there's a backend error and there are no frontend errors
        setErrorModal(true);
      } else if (result.paymentIntent?.status === "succeeded") {
        const payload: any = {
          application_fee: postTipAmount.data.application_fee,
          email: email || null,
          status: 1,
          stripe_fee: postTipAmount.data.stripe_fee,
          stripe_transaction_id: result.paymentIntent?.id || null,
          tip_amount: postTipAmount.data.tip_amount,
          type: postTipAmount.data.type,
          uuid: postTipAmount.data.uuid,
          note: postTipAmount?.data?.note
        }
        if (isAllThreeFeatures) {
          payload.property_service_id = property_service_id
        }
        // Payment succeeded, call send-tip API
        dispatch(
          sendTipApi({
            data
              : payload, sessionId
          })
        )
          .unwrap()
          .then((res: any) => {
            navigate("/payment-completed");
          });

        // Navigate to payment completed page
      }
    }
    setLoading(false);
  };
  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const regex = /^(?:[^\s@]+@[^\s@]+\.[^\s@]+)?$/;
    return regex.test(email);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    if (!validateEmail(inputEmail)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
  };
  useEffect(() => {
    setStripeLoading(true);
    setTimeout(() => {
      setStripeLoading(false);
    }, 3000);
  }, []);

  return (
    <div className={styles.stripe_container}>
      <form>
        {stripeLoading ? (
          <span
            className="loader"
            style={{
              display: "flex",
              height: 40,
            }}
          >
            <RotatingLines
              visible={true}
              strokeColor="#000000"
              width={"25px"}
            />
          </span>
        ) : (
          <PaymentElement key={stripeLoading ? "loading" : "loaded"} />
        )}
        <div className={styles.stripe_wrapper} style={{ marginBottom: 15 }}>
          <Row>
            <Col lg={12} className="mt-1">
              <label htmlFor="email">
                Email address <span>Optional</span>
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) => {
                  setError("");
                  handleEmailChange(e);
                }}
                value={email}
                placeholder="Enter email"
                className={styles.email}
                style={{ marginBottom: 0 }}
              />
              {error && (
                <p
                  style={{
                    color: "red",
                    textAlign: "end",
                    fontSize: 12,
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                  }}
                >
                  {error}
                </p>
              )}
              {/* New Checkbox Section */}
              <div
                className={styles.checkbox_container}
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <input
                  type="checkbox"
                  id="termsCheckbox"
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                  className={styles.checkbox}
                />
                <label
                  htmlFor="termsCheckbox"
                  style={{ marginLeft: 10, color: "black", fontSize: "14px" }}
                >
                  I agree with the{" "}
                  <a
                    href="https://toptipper.s3.amazonaws.com/TermsofServiceSept2022.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        color: "blue",
                        fontSize: "14px",
                      }}
                    >
                      Terms and Conditions
                    </span>
                  </a>
                </label>
              </div>

            </Col>
          </Row>
        </div>
        <div className={styles.btn}>
          <ThemeButton
            disabled={!!(email.length > 0 && error) || loading || !termsChecked || stripeLoading}
            onClick={(e) => handleSubmit(e)}
            loading={loading || sendTipLoading}
          >
            Send tip
          </ThemeButton>
        </div>
      </form>
      <ConfirmationModal
        handleToggle={handleErrorModal}
        icon={oops}
        title={""}
        color={"#676D7A"}
        desc={"Your card has been declined."}
        show={errorModal}
      />{" "}
    </div>
  );
}
